import type { ButtonHTMLAttributes, FC, PropsWithChildren } from 'react';
import { SVGSpinner } from 'Assets/svg';
import { twJoin, twMerge } from 'tailwind-merge';

export type ButtonProps = PropsWithChildren<{
    className?: string;
}> &
    ButtonHTMLAttributes<HTMLButtonElement>;
export const Button: FC<ButtonProps> = ({
    className = '',
    children,
    ...props
}) => {
    return (
        <button
            className={twMerge(
                `
                min-w-[150px] h-15
                px-13 py-3.5
                border rounded-full
                font-Montserrat text-dark-green-bg dark:text-bodydark font-bold text-center leading-[1.667]
                text-base dasktop:text-lg
                disabled:opacity-50 
            `,
                className
            )}
            type="button"
            {...props}
        >
            {children}
        </button>
    );
};

export const ButtonActive_0: FC<ButtonProps> = ({
    className = '',
    children,
    ...props
}) => {
    return (
        <Button className={`bg-brand text-white  ${className}`} {...props}>
            {children}
        </Button>
    );
};
export const ButtonGreen: FC<ButtonProps> = ({
    className,
    children,
    ...props
}) => {
    return (
        <Button
            className={`bg-brand-green text-white border-brand-green ${className}`}
            {...props}
        >
            {children}
        </Button>
    );
};
export const ButtonGray: FC<ButtonProps> = ({
    className = '',
    children,
    ...props
}) => {
    return (
        <Button
            className={
                `
                bg-brand bg-opacity-10 rounded-lg px-7 py-3.5
                text-brand whitespace-nowrap
                ` + className
            }
            {...props}
        >
            {children}
        </Button>
    );
};

export const ButtonWithSpinner: FC<
    PropsWithChildren<{
        loading: boolean;
        className?: string;
        spinnerCentered?: boolean;
    }>
> = ({ loading, className = '', spinnerCentered = true, children }) => {
    return (
        <div
            className={twMerge(
                'relative  inline-block',
                !spinnerCentered && 'pr-10 ',
                className
            )}
        >
            {children}
            {loading && (
                <SVGSpinner
                    className={twJoin(
                        'w-10 h-10 absolute y-middle',
                        spinnerCentered ? 'x-center' : 'right-0'
                    )}
                />
            )}
        </div>
    );
};

// -------- new design -------- //

export const ButtonActive: FC<ButtonProps> = ({
    className = '',
    children,
    ...props
}) => {
    return (
        <Button
            className={`
                bg-accent border-accent 
                disabled:bg-button-disabled disabled:border-button-disabled 
                dark:text-gray-900
                ${className}
            `}
            {...props}
        >
            {children}
        </Button>
    );
};

export const ButtonHollow: FC<ButtonProps> = ({
    className = '',
    children,
    ...props
}) => {
    return (
        <Button
            className={twMerge(
                `
                border-accent 
                disabled:bg-button-disabled disabled:border-button-disabled 
            `,
                className
            )}
            {...props}
        >
            {children}
        </Button>
    );
};
