import { useId, type FC } from 'react';
import { Tooltip } from 'react-tooltip';

type DescriptionProps = {
    text: string;
    className?: string;
    preventDeaultClick?: boolean;
};
export const Description: FC<DescriptionProps> = ({
    text,
    className = '',
    preventDeaultClick,
}) => {
    const tooltipId = useId();

    return text ? (
        <>
            <span
                onClick={(e) => {
                    if (preventDeaultClick) {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }}
                className={
                    `
                  inline-block ml-[0.375em] my-0.5 w-[1.375em] h-[1.375em] border rounded-1/2
                  font-medium text-center leading-[1.375em] tracking-[0] normal-case
                  cursor-pointer
                  ` + className
                }
                data-tooltip-id={tooltipId}
                data-tooltip-place="bottom"
                data-tooltip-variant="light"
            >
                i
            </span>
            <Tooltip
                id={tooltipId}
                openOnClick
                opacity={1}
                globalCloseEvents={{
                    escape: true,
                    scroll: true,
                    clickOutsideAnchor: true,
                }}
            >
                <div className="max-w-[190px] font-medium text-neutral-400 text-sm leading-[171.429%] normal-case">
                    {text}
                </div>
            </Tooltip>
        </>
    ) : null;
};
