import { forwardRef, useState } from 'react';
import { PassHiddenSVG, PassShownSVG } from 'Assets/svg';
import { Input, InputProps } from './Input.tsx';

export const InputPassword = forwardRef<HTMLInputElement, InputProps>(
    (props, ref) => {
        const [hidden, setHidden] = useState(true);
        const toggle = () => {
            setHidden((s) => !s);
        };
        const type = hidden ? 'password' : 'text';
        const Icon = hidden ? PassHiddenSVG : PassShownSVG;

        return (
            <Input
                {...props}
                ref={ref}
                type={type}
                icon={
                    <Icon
                        onClick={toggle}
                        role="button"
                        className="h-full w-auto"
                    />
                }
            />
        );
    }
);
