import { atom, selector } from 'recoil';
import { User } from '@auth0/auth0-react';
import { getUserRoles } from '../Services/auth/utils.ts';

export const auth0UserAtom = atom<User | undefined>({
    key: 'auth0UserAtom',
    default: undefined,
});

export const userIdSelector = selector({
    key: 'userIdSelector',
    get: ({ get }) => get(auth0UserAtom)?.sub ?? '',
});

export const userRoleSelector = selector({
    key: 'userRoleSelector',
    get: ({ get }) => getUserRoles(get(auth0UserAtom))[0] ?? '',
});
