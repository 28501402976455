import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
//
import { heap } from 'Services/heap';
import { sentryInit } from 'Services/sentry';
import { RecoilRoot } from 'recoil';
// import { StrictMode } from 'react';
//
import './index.css';

import { baseURL } from 'app-constants.ts';
import { routes } from 'routes.tsx';
import { ToastContainer } from 'react-toastify';

heap.load();
const { sentryCreateBrowserRouter } = sentryInit();
console.info(import.meta.env.PACKAGE_VERSION);

ReactDOM.createRoot(document.getElementById('root')!).render(
    // <StrictMode>
    <RecoilRoot>
        <RouterProvider
            router={sentryCreateBrowserRouter(routes, {
                basename: baseURL.pathname,
            })}
        />
        <ToastContainer
            autoClose={3e3}
            icon={false}
            toastClassName="text-black dark:border-strokedark dark:bg-boxdark dark:text-bodydark"
        />
    </RecoilRoot>
    //</StrictMode>
);
