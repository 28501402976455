import sortBy from 'lodash/sortBy';
import { saveAs } from 'file-saver';
import dayjs, { Dayjs } from 'dayjs';
import has from 'lodash/has';
import startCase from 'lodash/startCase';
//
import { baseURL } from 'app-constants.ts';
import { DEFAULT_LOCALE, INVESTOR_DOC_TYPES } from 'constants.ts';
import { GridColumnsConfigType } from 'Apps/client-app/components/DataGrid.tsx';

export const getAbsoluteRouteURL = (route: string) =>
    new URL(route.replace(/^\//, ''), baseURL.href.replace(/([^/])$/, '$1/'))
        .href;

export function formSerialize<T>(form: HTMLFormElement) {
    const formData = new FormData(form);
    return Array.from(formData).reduce(
        (data, [key, value]) => Object.assign(data, { [key]: value }),
        {}
    ) as T;
}

export function thousandsFormat(n: number) {
    const [int, dec = ''] = Math.abs(n).toString().split('.');
    const intTransform = int
        .split('')
        .reverse()
        .reduce<string[]>((s, v, idx, ar) => {
            const pos = idx + 1;
            if (pos % 3 === 0 && pos !== ar.length) {
                return s.concat([v, ',']);
            } else return s.concat(v);
        }, [])
        .reverse()
        .join('');
    const value = intTransform + '.' + dec.padStart(2, '0');
    return n > 0 ? value : '-' + value;
}

export function getUserLocale() {
    if (typeof window === 'undefined') return DEFAULT_LOCALE;
    const locale = window.Intl?.NumberFormat().resolvedOptions().locale;
    return (
        locale ||
        window.navigator.language ||
        window.navigator.languages?.[0] ||
        DEFAULT_LOCALE
    );
}

export function dateRepresent(
    value: Date | string | null | undefined,
    options?: Intl.DateTimeFormatOptions
) {
    return (
        value && new Date(value).toLocaleDateString(getUserLocale(), options)
    );
}

export function moneyRepresent(
    amount: number | null | undefined,
    currency: string | null | undefined = 'USD',
    compact?: boolean
) {
    const userLocale = getUserLocale();
    let moneyString: string;

    if (amount == null) return 'N/A';
    if (!currency) return thousandsFormat(amount);
    if (typeof Intl === 'undefined') {
        moneyString = `${thousandsFormat(amount)} ${currency}`;
    } else {
        moneyString = new Intl.NumberFormat([userLocale, DEFAULT_LOCALE], {
            style: 'currency',
            currency,
            notation: compact ? 'compact' : undefined,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(amount);
    }
    return moneyString;
}

export function percentRepresent(value: number, maximumFractionDigits = 2) {
    return new Intl.NumberFormat([getUserLocale(), DEFAULT_LOCALE], {
        style: 'percent',
        maximumFractionDigits,
    }).format(Number.isFinite(value) ? value : 0);
}

export function isNotFalsy<D>(v: D | null | undefined | false): v is D {
    return v != null && v !== false;
}

export function isNotFile<T>(v: File | T): v is T {
    return !(v instanceof File);
}
export function isFile<T>(v: File | T): v is File {
    return v instanceof File;
}
export function isSameFiles(file1: File, file2: File) {
    return (
        file1.name === file2.name &&
        file1.size === file2.size &&
        file1.lastModified === file2.lastModified
    );
}

export const parseTime = (seconds: number) => {
    const min = Math.floor(seconds / 60);
    const sec = seconds % 60;
    const pad0 = (n: number) => String(n).padStart(2, '0');
    return `${pad0(min)}:${pad0(sec)}`;
};

export function formatString(format: string, value: string) {
    const pureValue = value.replace(/\D/g, '');

    const { string } = format.split('').reduce(
        (result, x) => {
            const { string, position, pureValue } = result;
            if (pureValue.at(position) == null) return result;
            if (x === 'x') {
                return {
                    ...result,
                    string: string + pureValue.at(position),
                    position: position + 1,
                };
            } else {
                return { ...result, string: string + x };
            }
        },
        { string: '', position: 0, pureValue }
    );
    return string;
}

export function getPayoutId(curId?: string | string[]) {
    // payout_id format YYYY-MM/X
    const now = new Date();
    const nowMonth = String(now.getMonth() + 1).padStart(2, '0');
    const newId = `${now.getFullYear()}-${nowMonth}`;

    if (Array.isArray(curId)) {
        curId = sortBy(curId, (v) => Number(v.split('/')[1] || 0)).at(-1);
    }
    if (!curId) return newId;
    const [curBase, curX] = curId.split('/');
    if (curBase === newId) return newId + '/' + (+curX + 1 || 1);
    else return newId;
}

export function getMonthStart(date = new Date()) {
    date.setUTCDate(1);
    date.setUTCHours(0, 0, 0, 0);
    return date.toISOString().replace('Z', '+00:00');
}

export function generateId(
    prefix: '' | undefined,
    startN?: number,
    negative?: boolean
): () => number;
export function generateId(
    prefix: string,
    startN?: number,
    negative?: boolean
): () => string;
export function generateId(prefix = '', startN = 0, negative = false) {
    return () => {
        const newId = negative ? --startN : startN++;
        return prefix ? `${prefix}${newId}` : newId;
    };
}

export function isActive<
    T extends { active?: boolean },
    Active extends { active: true },
>(item: Active | T): item is Active {
    return item.active === true;
}
export function isDeactivated<
    T extends { active?: boolean },
    Deactivated extends { active: false },
>(item: T | Deactivated): item is Deactivated {
    return item.active === false;
}

export function isIssuedId(
    doc: InvestorDocumentType
): doc is InvestorDocumentType<INVESTOR_DOC_TYPES.IssuedId> {
    return doc.type === INVESTOR_DOC_TYPES.IssuedId;
}
export function isProof(
    doc: InvestorDocumentType
): doc is InvestorDocumentType<INVESTOR_DOC_TYPES.Proof> {
    return doc.type === INVESTOR_DOC_TYPES.Proof;
}

export function isPayout<P extends { payout_amount: number }, T extends object>(
    item: P | T
): item is P {
    return has(item, 'payout_amount');
}

export function isInvestment<
    I extends { invested_amount: number },
    T extends object,
>(item: I | T): item is I {
    return has(item, 'invested_amount');
}

export const isImage = (file: File | UserFileType) => {
    return /^image\//.test(isFile(file) ? file.type : file.mimeType);
};

export function measureTextSizes(ctx: CanvasRenderingContext2D, text: string) {
    const textBox = ctx.measureText(text);
    const textWidth = textBox.width;
    const textHeight =
        textBox.actualBoundingBoxAscent + textBox.actualBoundingBoxDescent;

    return {
        width: textWidth,
        height: textHeight,
    };
}

export function downloadCSV(csvData: (string | number)[][], name: string) {
    const CSVSeparator = ',';
    const csvString = csvData
        .map((csvRow) => csvRow.join(CSVSeparator))
        .join('\n');

    const blob = new Blob([csvString], {
        type: 'text/cvs;charset=utf-8',
    });
    saveAs(blob, `${name}.csv`);
}

export function nextMonthDate(
    dateString: string | Date,
    monthAmount?: number
): Date;
export function nextMonthDate(
    dateString: string | Date,
    monthAmount: number,
    options: Intl.DateTimeFormatOptions
): string;
export function nextMonthDate(
    dateString: string | Date,
    monthAmount = 1,
    options?: Intl.DateTimeFormatOptions
) {
    const date = new Date(dateString);
    date.setMonth(date.getMonth() + monthAmount);
    return options ? dateRepresent(date, options) : date;
}

// export function nextMonthDateUTC(dateString: Date, monthAmount?: number): Date;
// export function nextMonthDateUTC(
//     dateString: Date,
//     monthAmount: number,
//     options: Intl.DateTimeFormatOptions
// ): string;
// export function nextMonthDateUTC(
//     dateString: Date,
//     monthAmount = 1,
//     options?: Intl.DateTimeFormatOptions
// ) {
//     const date = new Date(dateString);
//     date.setUTCMonth(date.getUTCMonth() + monthAmount);
//     return options ? dateRepresent(date, options) : date;
// }

export function monthStart(dateString?: string | Date, utc?: boolean) {
    const date = dateString ? new Date(dateString) : new Date();
    if (utc) {
        date.setUTCDate(1);
        date.setUTCHours(0);
        date.setUTCMinutes(0);
        date.setUTCSeconds(0);
        date.setUTCMilliseconds(0);
    } else {
        date.setDate(1);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
    }
    return date;
}

export const returnToHandler = {
    setReturnTo(returnToPath?: string) {
        returnToPath && localStorage.setItem('returnTo', returnToPath);
    },
    pullReturnToPath() {
        const returnTo = localStorage.getItem('returnTo');
        localStorage.removeItem('returnTo');
        return returnTo;
    },
};

export const dateToMonthValue = (monthDayjs: Dayjs) =>
    monthDayjs.startOf('month').format('YYYY-MM-DD');

export const monthOptions = (() => {
    const curDayjs = dayjs();
    const monthsDates: Dayjs[] = [];

    for (let i = -12; i <= 12; i++) {
        monthsDates.push(curDayjs.add(i, 'month'));
    }

    return monthsDates.map((monthDate) => ({
        value: dateToMonthValue(monthDate),
        label: dateRepresent(monthDate.toDate(), {
            month: 'long',
            year: 'numeric',
        }) as string,
    }));
})();

export function getCSVData<D extends object>(
    config: GridColumnsConfigType<D>,
    data: D[]
) {
    const header = config.map((conf) =>
        parseString(startCase(conf.exportHeader ?? String(conf.header)))
    );
    const rows = data.map((dataItem) =>
        config.map((conf) => {
            const value = conf.value(dataItem);
            const csvValue = conf.valueExport
                ? conf.valueExport(value, dataItem)
                : value
                ? String(value)
                : '';
            if (typeof csvValue === 'number') return csvValue;
            else return parseString(csvValue);
        })
    );
    return ([] as Array<string | number>[]).concat([header], rows);

    function parseString(str: string) {
        return `"${str.replaceAll('"', '""')}"`;
    }
}

export function listToOptions<T>(items: Array<T>) {
    return items.map((value) => ({ label: String(value), value }));
}

export function routingNumberValidation(rNumber: string) {
    if (!/\d{9}/.test(rNumber)) return false;

    const d = rNumber.split('').map(Number);
    const sum =
        3 * (d[0] + d[3] + d[6]) +
        7 * (d[1] + d[4] + d[7]) +
        (d[2] + d[5] + d[8]);

    return sum % 10 === 0;
}

export function getAccountNumberMask(accountNumber?: string) {
    return accountNumber?.slice(-4);
}

export const roundDownToTwoDecimals = (value: number): number => {
    return Math.floor(value * 100) / 100;
};
