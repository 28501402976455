import * as Sentry from '@sentry/react';
//
import sentryConfig from './sentryConfig.ts';
import { useEffect } from 'react';
import {
    createBrowserRouter,
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';

export function sentryInit() {
    Sentry.init({
        environment: sentryConfig.environment,
        dsn: sentryConfig.sentryDsn,
        release: sentryConfig.release,
        dist: sentryConfig.sentryReleaseDist,
        autoSessionTracking: false,

        // See docs for support of different versions of variation of React router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                ),
            }),
        ],

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            'localhost',
            /^\//,
            new RegExp(`^${import.meta.env.VITE_APP_DJANGO_API_ENTRYPOINT}`),
            new RegExp(`^${import.meta.env.VITE_APP_GRAPHQL_HTTP_ENDPOINT}`),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
    return {
        sentryCreateBrowserRouter:
            Sentry.wrapCreateBrowserRouter(createBrowserRouter),
    };
}
export function updateSentryUser<
    T extends { id?: string; username?: string; email?: string }
>(userData: T | null = null) {
    Sentry.setUser(
        userData
            ? {
                  id: userData.id,
                  username: userData.username,
                  email: userData.email,
              }
            : null
    );
}
