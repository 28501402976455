import { useLayoutEffect } from 'react';
import { useSetRecoilState } from 'recoil';
//
import { scrollBarWidthAtom } from 'Recoil/layout.ts';

export function useCheckScrollBarWidth() {
    const setScrollBarWidth = useSetRecoilState(scrollBarWidthAtom);

    useLayoutEffect(() => {
        const block = document.createElement('div');
        block.style.width = 50 + 'px';
        block.style.height = 50 + 'px';
        block.style.overflow = 'auto';

        const inBlock = document.createElement('div');
        inBlock.style.height = 100 + 'px';

        block.append(inBlock);
        document.body.append(block);

        const scrollBarWidth = block.offsetWidth - inBlock.offsetWidth;
        block.remove();
        setScrollBarWidth(scrollBarWidth);
    }, [setScrollBarWidth]);
}
