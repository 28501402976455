import type { FC, HTMLAttributes } from 'react';

type Container = FC<HTMLAttributes<HTMLDivElement>>;

export const MsgContainer: Container = ({ children, className = '' }) => (
    <div
        className={
            `w-full border-l-6 px-3 py-4 
            bg-opacity-[15%] dark:bg-opacity-30 shadow-md 
            grid gap-x-5 gap-y-1 ` + className
        }
        style={{
            gridTemplateColumns: 'min-content auto',
            gridTemplateRows: 'repeat(2, min-content)',
        }}
    >
        {children}
    </div>
);

export const MsgIconContainer: Container = ({ className = '', children }) => (
    <div
        className={
            'h-9 w-9 row-span-full flex items-center justify-center rounded-lg ' +
            className
        }
    >
        {children}
    </div>
);

export const MsgTitleContainer: Container = ({ className = '', children }) => (
    <div className={'text-lg font-semibold ' + className}>{children}</div>
);
export const MsgMesasgeContainer: Container = ({
    className = '',
    children,
}) => {
    return (
        <div className={'text-base leading-relaxed ' + className}>
            {children}
        </div>
    );
};
