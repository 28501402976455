import type { FC, PropsWithChildren, ReactElement } from 'react';
import { Children, cloneElement, isValidElement } from 'react';

type SideContentProps = {
    children: ReactElement | ReactElement[];
};
export const SideContent: FC<PropsWithChildren<SideContentProps>> = ({
    children,
}) => {
    const mainItemClass =
        'text-2xl font-semibold leading-[250%] tracking-[0.09375rem]';
    const subItemClass =
        'mt-3.5 text-base text-white text-opacity-80 leading-[250%] tracking-[0.09375rem]';

    return Children.map(
        children,
        (child, idx) =>
            isValidElement(child) &&
            cloneElement(child as ReactElement, {
                className: idx ? subItemClass : mainItemClass,
            })
    );
};

export function sideContentRenderer(sideContent: string | string[]) {
    sideContent = ([] as string[]).concat(sideContent);
    const [mainItem, ...subItems] = sideContent;
    return (
        <>
            <p className="font-semibold text-2xl leading-[calc(2vw+30px)] large:leading-[250%] tracking-[0.09375rem]">
                {mainItem}
            </p>
            {subItems.map((item, idx) => (
                <p
                    key={idx}
                    className="mt-3.5 font-medium text-base text-white text-opacity-80 leading-[calc(2vw+30px)] large:leading-[250%] tracking-[0.09375rem]"
                >
                    {item}
                </p>
            ))}
        </>
    );
}
