import type { User } from '@auth0/auth0-react';

import { AUTH_CONFIG } from './auth0-config.ts';

export function getUserRoles<Auth0User extends User>(
    user?: Auth0User
): UserRole[] {
    return user?.[AUTH_CONFIG.domain].roles ?? [];
}

export function getUserMetaData<Auth0User extends User>(user?: Auth0User) {
    return user?.[AUTH_CONFIG.domain].user_metadata ?? {};
}
