import type { InputHTMLAttributes, ReactElement, ReactNode } from 'react';
import { forwardRef, useId } from 'react';
import { twMerge } from 'tailwind-merge';
//
import { formatString } from 'Helpers/utils.ts';
import { ErrorMessage } from './ErrorMessage.tsx';
import { Description } from '../Description.tsx';

export type InputProps = Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'onInput'
> & {
    label?: string;
    description?: string;
    format?: string;
    preIcon?: ReactElement;
    icon?: ReactElement;
    error?: ReactNode;
    containerClassname?: string;
    inputClassname?: string;
    onInput?(value: string, name: string): void;
};
export const Input = forwardRef<HTMLInputElement, InputProps>(
    (
        {
            label = '',
            error,
            description,
            containerClassname = '',
            onInput,
            placeholder,
            format,
            preIcon,
            icon,
            inputClassname = '',
            ...props
        },
        ref
    ) => {
        const id = useId();
        props.id = props.id ?? id;
        return (
            <div
                className={twMerge('flow-root pt-[0.62em]', containerClassname)}
            >
                <div className="relative text-base">
                    <input
                        ref={ref}
                        className={twMerge(
                            `peer w-full
                            self-stretch px-9.5 py-3.5
                            border rounded-full border-input-border
                            font-medium text-input-value text-lg
                            focus:outline-none
                            disabled:bg-input-bg-disabled disabled:text-input-value-disabled
                          `,
                            // error && 'border-red-700',
                            props.type === 'number' && 'no-spinner',
                            inputClassname
                        )}
                        {...props}
                        placeholder={placeholder || format}
                        onInput={({ currentTarget: { value, name } }) => {
                            value = format
                                ? formatString(format, value)
                                : value;
                            onInput?.(value, name);
                        }}
                        onWheel={(e) => {
                            if (props.type === 'number') {
                                e.currentTarget.blur();
                                return false;
                            }
                        }}
                    />
                    {preIcon && (
                        <span
                            className="
                              absolute y-middle left-2 w-6.5 h-[50%] flex items-center
                              font-medium text-input-value text-lg
                            "
                        >
                            {preIcon}
                        </span>
                    )}
                    <label
                        htmlFor={props.id}
                        className="
                          absolute left-8 px-2 top-[2px] -translate-y-1/2
                          text-label font-medium
                          bg-gradient-to-b
                          label-gradient
                        "
                    >
                        <div className="relative -top-[1px]">
                            {label}
                            {description && (
                                <Description
                                    text={description}
                                    className="text-[0.69rem]"
                                />
                            )}
                        </div>
                    </label>
                    {icon && (
                        <span className="absolute top-1/2 -translate-y-1/2 right-6 h-[50%] flex items-center">
                            {icon}
                        </span>
                    )}
                </div>
                <ErrorMessage error={error}></ErrorMessage>
            </div>
        );
    }
);

export const InputHollow = forwardRef<HTMLInputElement, InputProps>(
    ({ label, error, onInput, id, ...props }, ref) => {
        const genId = useId();
        id = id || genId;
        return (
            <div className=" w-full text-[0.9375rem]">
                <div className="relative mt-2 font-medium text-xl">
                    <input
                        ref={ref}
                        id={id}
                        className="
                            input-hollow
                            w-full max-w-lg bg-transparent border-b
                            py-2 pr-3 pl-0
                            focus:outline-none
                        "
                        onInput={({ currentTarget: { value, name } }) =>
                            onInput?.(value, name)
                        }
                        {...props}
                        placeholder=" "
                    />
                    <label
                        htmlFor={id}
                        className="
                        transition-all
                        absolute
                        left-0
                         font-medium
                    "
                    >
                        {label}
                    </label>
                </div>
                <ErrorMessage error={error} />
            </div>
        );
    }
);
