import { FC } from 'react';
//
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
//
import { updateSentryUser } from 'Services/sentry';
import { heap } from 'Services/heap';
import { auth0UserAtom } from 'Recoil/auth0-user.ts';
import { appUserAtom } from 'Recoil/app-user.ts';
import { useCheckScrollBarWidth } from 'Helpers/hooks/useCheckScrollBarWidth.ts';

if (
    import.meta.env.VITE_APP_ENVIRONMENT === 'development' ||
    import.meta.env.DEV
) {
    // Adds messages only in a dev environment
    loadDevMessages();
    loadErrorMessages();
}

type AppProps = object;
export const App: FC<AppProps> = () => {
    const auth0User = useRecoilValue(auth0UserAtom);
    const appUser = useRecoilValue(appUserAtom);

    useEffect(() => {
        if (!auth0User) return;
        //set Sentry user
        updateSentryUser(
            auth0User && { id: auth0User.sub, email: auth0User.email }
        );
        //set Heap userId
        heap.identify(auth0User?.sub);
        //update HeapUser
        heap.addUserProperties({
            email: auth0User?.email,
        });
    }, [auth0User]);

    useEffect(() => {
        if (!appUser) return;
        const username = appUser.full_name;
        updateSentryUser({
            id: appUser.user_id,
            email: appUser.email,
            username,
        });
        heap.addUserProperties({
            name: username,
            user_role: appUser.role,
        });
    }, [appUser]);

    useCheckScrollBarWidth();

    return <Outlet />;
};
