import type { TypeOptions } from 'react-toastify';
import type { ReactNode } from 'react';
//
import { toast } from 'react-toastify';
import { useCallback, useEffect, useId, useRef } from 'react';
import { ApolloError } from '@apollo/client';
import has from 'lodash/has';
import get from 'lodash/get';

import { ErrorMsg } from 'Components/alerts/ErrorMsg.tsx';
import { SuccessMsg } from 'Components/alerts/SuccessMsg.tsx';
import { WarningMsg } from 'Components/alerts/WarningMsg.tsx';
import { parseGQLError } from '../gql-helpers.ts';

export function useShowToast() {
    const toastId = useId();
    const toastType = useRef<TypeOptions>();

    const showError = useCallback(
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        (error: any | ApolloError, title?: string) => {
            if (error === null) toast.dismiss(toastId);
            else {
                error =
                    error instanceof ApolloError
                        ? parseGQLError(error)
                        : parseError(error);
                toastType.current = toast.TYPE.ERROR;
                showToast(
                    toastId,
                    <ErrorMsg title={title ?? 'Error'}>
                        {error || 'error occurred'}
                    </ErrorMsg>,
                    toast.TYPE.ERROR,
                    false
                );
            }
        },
        [toastId]
    );

    const showSuccess = useCallback(
        (
            successMsg?: ReactNode,
            title?: string,
            autoClose?: number | false
        ) => {
            toastType.current = toast.TYPE.SUCCESS;
            showToast(
                toastId,
                <SuccessMsg title={title ?? 'Success'}>
                    {successMsg}
                </SuccessMsg>,
                toast.TYPE.SUCCESS,
                autoClose
            );
        },
        [toastId]
    );

    const showWarning = useCallback(
        (
            successMsg?: ReactNode,
            title?: string,
            autoClose?: number | false
        ) => {
            toastType.current = toast.TYPE.WARNING;
            showToast(
                toastId,
                <WarningMsg title={title ?? 'Success'}>
                    {successMsg}
                </WarningMsg>,
                toast.TYPE.WARNING,
                autoClose
            );
        },
        [toastId]
    );
    const dismiss = useCallback(() => toast.dismiss(toastId), [toastId]);

    useEffect(
        () => () => {
            if (toastType.current !== toast.TYPE.SUCCESS) dismiss();
        },
        [dismiss, toastType]
    );
    return { showError, showSuccess, showWarning, dismiss };
}

function showToast(
    toastId: string,
    content: ReactNode,
    type: TypeOptions,
    autoClose?: number | false | undefined
) {
    if (toast.isActive(toastId)) {
        return toast.update(toastId, {
            render: () => content,
            type,
            autoClose,
        });
    }
    return toast(content, { toastId, type, autoClose });
}

export const parseError = (
    error?: string | string[] | Record<string, string>
): ReactNode => {
    if (!error) return '';
    if (typeof error === 'string') return error;
    if (has(error, 'data.message')) return get(error, 'data.message');
    if (has(error, 'data.status')) return get(error, 'data.status');
    if (has(error, 'statusText')) return get(error, 'statusText');
    if (has(error, 'message')) return get(error, 'message');
    if (Array.isArray(error)) {
        return (
            <ul>
                {error.map((error) => (
                    <li key={String(error)}>{parseError(error)}</li>
                ))}
            </ul>
        );
    }
    if (typeof error === 'object') {
        const errorlsList = Object.entries(error).map(
            ([key, message]) => `${key}: ${message}`
        );
        return parseError(errorlsList);
    }
    return String(error);
};
