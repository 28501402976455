import type { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type ErrorMessageProps = {
    className?: string;
    error?: ReactNode;
};
export const ErrorMessage: FC<ErrorMessageProps> = ({
    className = '',
    error,
}) => {
    return error ? (
        <p
            className={twMerge(
                `text-danger mt-[0.62em] text-xs font-light`,
                className
            )}
        >
            {error}
        </p>
    ) : null;
};
