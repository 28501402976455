import type { ReactNode } from 'react';
import { Navigate, Path, useLocation, useParams } from 'react-router-dom';

export function ExternalLinks<P extends string | Record<string, string>>({
    children,
    redirect,
}: {
    redirect?: string;
    children?:
        | ReactNode
        | ((
              params: ReturnType<typeof useParams<P>>,
              search?: string,
              hash?: string
          ) => ReactNode);
}) {
    const params = useParams<P>();
    const { search, hash } = useLocation();

    if (redirect)
        return (
            <Navigate
                to={{
                    pathname: redirect,
                    search,
                    hash,
                }}
                replace
            />
        );
    return typeof children === 'function'
        ? children(params, search, hash)
        : children;
}

ExternalLinks.redirect = (to: Partial<Path>) => {
    return <Navigate to={to} replace />;
};
