import type { FC, PropsWithChildren, ReactNode } from 'react';
import { SVGLogo } from 'Assets/svg';
import { sideContentRenderer } from './SideContent.tsx';

type OnboardingLayoutProps = {
    gridCOleumnsClass?: string;
    sideContent?: ReactNode;
    sideImage?: string;
    steps?: number;
    currStep?: number;
};
export const OnboardingLayout: FC<PropsWithChildren<OnboardingLayoutProps>> = ({
    gridCOleumnsClass,
    sideContent = null,
    sideImage,
    steps = 0,
    currStep = 0,
    children,
}) => {
    gridCOleumnsClass =
        gridCOleumnsClass ??
        'grid-cols-[35.69%_1fr] large:grid-cols-[32.125rem_1fr]';

    sideContent =
        typeof sideContent === 'string' || Array.isArray(sideContent)
            ? sideContentRenderer(sideContent)
            : sideContent;
    return (
        <div className={`min-h-full grid ${gridCOleumnsClass}`}>
            <div
                className="
                    side hidden desktop:block bg-gradient-to-r side-gradient
                    font-Raleway text-white
               "
            >
                <div className="min-h-full flex flex-col">
                    <div className="m-4 w-12 desktop:w-16">
                        <SVGLogo className="w-full h-auto" />
                    </div>
                    <div className="my-13 mx-[3.333vw] large:mx-12">
                        {sideContent}
                    </div>

                    <div className="flex-grow flex flex-col">
                        {sideImage && (
                            <div className="mt-auto sticky bottom-0">
                                <img
                                    src={sideImage}
                                    className=" w-auto max-w-[75%] h-auto"
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <main
                className="
                    desktop:self-start
                    min-h-screen w-full max-w-[56.375rem] mx-auto
                    col-span-full desktop:col-span-1
                    py-11 desktop:py-12 px-5 desktop:px-[5.555vw] large:px-20
                    flex flex-col
                "
            >
                {!!(steps && currStep) && (
                    <aside className="">
                        <OnboardingSteps steps={steps} curStep={currStep} />
                    </aside>
                )}
                <div className="mt-8 desktop:mt-18 desktop:basis-0 flex-grow">
                    {children}
                </div>
            </main>
        </div>
    );
};

const OnboardingSteps: FC<{
    steps: number;
    curStep: number;
}> = ({ steps, curStep }) => {
    const stepsEl = Array.from(Array(steps)).map((_, idx) => (
        <span
            key={idx}
            className={`block
                ${idx <= curStep ? 'bg-side-bg' : 'bg-[#DDD]'}
                h-3 desktop:h-4 max-w-[2rem] desktop:max-w-[3rem] basis-0 grow  
                rounded-full
            `}
        ></span>
    ));
    return (
        <div className="flex mx-2 gap-x-3.5 desktop:gap-x-5 justify-center">
            {stepsEl}
        </div>
    );
};
