import type {
    InputHTMLAttributes,
    PropsWithChildren,
    ReactElement,
    ReactNode,
} from 'react';
import { forwardRef, useId } from 'react';
import { Tooltip } from 'react-tooltip';

import { SVGTooltip } from 'Assets/svg';

type InputRadioProps = InputHTMLAttributes<HTMLInputElement> & {
    label?: ReactNode;
    showCircle?: boolean;
    children?: ReactNode;
};
export const InputRadio = forwardRef<HTMLInputElement, InputRadioProps>(
    (
        { label, showCircle = true, id, className = '', children, ...props },
        ref
    ) => {
        const customId = useId();
        const inputId = id ?? customId;
        return (
            <label
                className={`radio-input flex flex-nowrap text-dark-green-bg dark:text-label gap-[0.55em] ${className}`}
            >
                <input
                    ref={ref}
                    id={inputId}
                    {...props}
                    type="radio"
                    style={{ clip: 'rect(0,0,0,0)' }}
                    className="peer absolute"
                />
                {showCircle && (
                    <label
                        htmlFor={inputId}
                        className="
                            relative flex-shrink-0 flex-grow-0 flex
                            w-[1.33em] h-[1.33em] border-[0.09em] rounded-[50%]

                            peer-disabled:text-text-disabled

                            peer-checked:before:absolute peer-checked:before:block
                            peer-checked:before:rounded-[50%] peer-checked:before:inset-[15%]
                            peer-checked:before:bg-current
                        "
                    />
                )}
                {label && (
                    <label
                        htmlFor={inputId}
                        className="
                            peer-disabled:text-text-disabled
                            leading-[1.33em]
                        "
                    >
                        {label}
                    </label>
                )}
                {children}
            </label>
        );
    }
);

/**
 *
 * example
 *    <RadioGroup
 *         groupCaption="group caption"
 *     >
 *         <InputRadio
 *             label={'label 1'}
 *             name="name"
 *             defaultChecked
 *         />
 *         <InputRadio label={'label2'} name="name" />
 *     </RadioGroup>
 *  or
 *     <RadioGroup
 *        groupCaption="group caption"
 *        options={[
 *            { value: 'value1', label: 'label1' },
 *            { value: 'value2', label: 'label2' },
 *            { value: true, label: 'true' },
 *        ]}
 *        defaultChecked={true}
 *        onChange={(value) => console.log(value)}
 *    />
 */
type CommonGroupProps = {
    groupCaption: string | ReactElement;
    name?: string;
    className?: string;
    required?: boolean;
    row?: boolean;
    tooltip?: string;
    register?(): InputHTMLAttributes<HTMLInputElement>;
};
export function RadioGroup({
    groupCaption,
    className,
    children,
}: PropsWithChildren<CommonGroupProps>): ReactElement;
export function RadioGroup<
    Options extends {
        value: string | number | boolean | null;
        label: string;
    }[],
>({
    groupCaption,
    className,
}: CommonGroupProps & {
    options: Options;
    onChange?(value: Options[number]['value'], name?: string): void;
    name?: string;
    defaultChecked?: Options[number]['value'];
    checked?: Options[number]['value'];
}): ReactElement;

export function RadioGroup<
    Options extends {
        value: string | number | boolean | null;
        label: string;
    }[],
>(
    props: PropsWithChildren<
        CommonGroupProps & {
            options?: Options;
            onChange?(value: Options[number]['value'], name?: string): void;
            name?: string;
            defaultChecked?: Options[number]['value'];
            checked?: Options[number]['value'];
        }
    >
) {
    const {
        groupCaption,
        tooltip,
        required,
        row,
        className,
        options,
        onChange,
        register,
    } = props;
    const name = useId();
    const inputName = props.name ?? name;
    return (
        <div className={`my-2 ${className || ''}`}>
            <div className="font-semibold mb-2">
                {groupCaption}{' '}
                {required && <span className="opacity-70">(Required)</span>}
                {!!tooltip && (
                    <>
                        <span
                            className="inline-block ml-3"
                            data-tooltip-id={'tooltip_' + name}
                            data-tooltip-content={tooltip}
                        >
                            <SVGTooltip className="inline" />
                        </span>
                        <Tooltip id={'tooltip_' + name} />
                    </>
                )}
            </div>
            <div
                className={`flex ${
                    row ? 'flex-row flex-wrap' : 'flex-col'
                } gap-2`}
            >
                {props.children && props.children}
                {options &&
                    options.map(({ value, label }) => (
                        <InputRadio
                            key={String(value) + String(props.defaultChecked)}
                            label={label}
                            required={required}
                            name={inputName}
                            value={String(value)}
                            onChange={
                                onChange && (() => onChange(value, inputName))
                            }
                            {...(register ? register() : {})}
                            defaultChecked={
                                'defaultChecked' in props
                                    ? value === props.defaultChecked
                                    : undefined
                            }
                            checked={
                                'checked' in props
                                    ? value === props.checked
                                    : undefined
                            }
                        />
                    ))}
            </div>
        </div>
    );
}
