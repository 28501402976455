//
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
//
import { clientAppRoot, USER_TYPES } from 'constants.ts';
import { getAbsoluteRouteURL, returnToHandler } from 'Helpers/utils.ts';
import { changePassword, signIn, signUp } from 'Services/auth/auth0.ts';
import { useShowToast } from 'Helpers/hooks/useShowToast.tsx';
import { OnboardingLayout } from 'Components/layout/OnboardingLayout.tsx';
import { Input } from 'Components/form-elements/Input.tsx';
import { ButtonActive } from 'Components/form-elements/buttons.tsx';
import { InputPassword } from 'Components/form-elements/InputPassword.tsx';
import { InputRadio } from 'Components/form-elements/InputRadio.tsx';
import SideImgSrc from 'Assets/images/paper map with geometry.png';
import { ENABLE_BUSINESS_OWNER_SIGN_UP } from '../../app-constants.ts';

enum authType {
    signIn,
    signUp,
    resetPswd,
}
type FormData = {
    email: string;
    password: string;
    user_type: USER_TYPES;
};
type Auth0Error = {
    code: string;
    description: any; //eslint-disable-line  @typescript-eslint/no-explicit-any
    policy?: string;
    original?: Error;
};
type AuthProps = {
    returnTo?: string;
};
export const Auth: FC<AuthProps> = ({ returnTo }) => {
    const [type, setType] = useState(authType.signIn);
    const [error, setError] = useState<{
        general?: Auth0Error;
        username?: Auth0Error;
        password?: Auth0Error;
    }>({});
    const {
        register,
        handleSubmit,
        watch,
        formState: { isSubmitting },
    } = useForm<FormData>({
        defaultValues: {
            email: '',
            password: '',
            user_type: USER_TYPES.investor,
        },
    });
    const { showSuccess } = useShowToast();
    const formSubmit = async (data: FormData) => {
        setError({});
        if (type === authType.signIn)
            await signIn(data, getAbsoluteRouteURL(clientAppRoot), {
                returnTo,
            })
                .then(() => {
                    setError({});
                })
                .catch((error) => setError({ general: error }));

        if (type === authType.signUp)
            await signUp({
                email: data.email,
                password: data.password,
                user_metadata: { user_type: data.user_type },
            })
                .then(() => signIn(data, getAbsoluteRouteURL(clientAppRoot)))
                .catch((error) => {
                    if (error.code === 'invalid_password') {
                        setError({ password: error });
                    } else if (error.code === 'invalid_signup') {
                        setError({ username: error });
                    } else {
                        setError({ general: error });
                    }
                });

        if (type === authType.resetPswd)
            await changePassword(data.email)
                .then(() => {
                    showSuccess(
                        'Successfuly requested. Check your emails.',
                        'Password Change request'
                    );
                    setType(authType.signIn);
                })
                .then(() => {
                    setError({});
                })
                .catch((error) => setError({ general: error }));
    };

    useEffect(() => {
        setError({});
    }, [type]);

    useEffect(() => {
        returnToHandler.setReturnTo(returnTo);
    }, [returnTo]);
    return (
        <OnboardingLayout
            gridCOleumnsClass="grid-cols-[45%_1fr] large:grid-cols-[40.5rem_1fr]"
            sideContent={sideContent}
            sideImage={SideImgSrc}
        >
            <div className="h-full flex flex-col max-w-[37.5rem] desktop:mr-8">
                <h2
                    className="
                    mb-12 desktop:mb-16
                    font-Raleway font-semibold text-dark-green-bg
                    text-title-sm desktop:text-title-2lg
                "
                >
                    Let’s start with the e-mail and password you want to use for
                    your account.
                </h2>
                <form
                    id="auth-form"
                    onSubmit={handleSubmit(formSubmit)}
                    className=""
                >
                    {type === authType.signUp && (
                        <fieldset
                            disabled={isSubmitting}
                            className="mb-10.5 text-text3 text-base desktop:text-lg flex flex-wrap gap-x-10 gap-y-4"
                        >
                            <InputRadio
                                label="I am an investor"
                                {...register('user_type')}
                                value={USER_TYPES.investor}
                            />
                            <InputRadio
                                disabled={!ENABLE_BUSINESS_OWNER_SIGN_UP}
                                label="I am a business"
                                {...register('user_type')}
                                value={USER_TYPES.business_owner}
                            />
                        </fieldset>
                    )}
                    <fieldset
                        disabled={isSubmitting}
                        className="child-siblings:mt-10"
                    >
                        <Input
                            label="Email Address"
                            type="email"
                            required
                            id="email"
                            autoComplete="email"
                            spellCheck="false"
                            autoFocus
                            {...register('email')}
                        />
                        {error.username?.code === 'invalid_signup' && (
                            <ErrorMsg>
                                Looks like your account already exists. Please
                                use your email to log in to your account.
                            </ErrorMsg>
                        )}
                        {type !== authType.resetPswd && (
                            <>
                                <InputPassword
                                    label={'Password'}
                                    required
                                    spellCheck="false"
                                    autoComplete="password"
                                    id="password"
                                    {...register('password')}
                                />
                                {error.password?.code ===
                                    'invalid_password' && (
                                    <ErrorMsg>Password is too weak</ErrorMsg>
                                )}
                                <PasswordPolicy
                                    policyString={error.password?.policy}
                                    rules={error.password?.description?.rules}
                                />
                            </>
                        )}
                    </fieldset>
                    <fieldset
                        disabled={isSubmitting}
                        className="mt-4.5 font-semibold text-text-gray4 child-siblings:mt-2.5"
                    >
                        {(type === authType.signUp ||
                            type === authType.resetPswd) && (
                            <div>
                                Already have an account?{' '}
                                <span
                                    role="button"
                                    className="text-accent underline"
                                    onClick={() => setType(authType.signIn)}
                                >
                                    Login
                                </span>
                            </div>
                        )}
                        {(type === authType.signIn ||
                            type === authType.resetPswd) && (
                            <div>
                                Don't have an account?{' '}
                                <span
                                    role="button"
                                    className="text-accent underline"
                                    onClick={() => setType(authType.signUp)}
                                >
                                    Sign up
                                </span>
                            </div>
                        )}
                        {type === authType.signIn && (
                            <div>
                                Forgot password?{' '}
                                <span
                                    role="button"
                                    className="text-accent underline"
                                    onClick={() => setType(authType.resetPswd)}
                                >
                                    Reset password
                                </span>
                            </div>
                        )}
                    </fieldset>

                    <div className="mt-auto desktop:mt-20 text-danger font-medium text-sm">
                        <ErrorMsg>{error.general?.description}</ErrorMsg>
                    </div>
                </form>
                <div
                    className="
                        mt-auto desktop:flex-grow self-stretch
                        flex justify-end items-center
                    "
                >
                    <ButtonActive
                        form="auth-form"
                        type="submit"
                        disabled={isSubmitting}
                        className="w-full desktop:w-[66.45%] whitespace-nowrap min-w-[min-content]"
                    >
                        {type === authType.signIn && 'Login'}
                        {type === authType.signUp &&
                            'Let’s Start ' +
                                (watch('user_type') === USER_TYPES.investor
                                    ? 'Investing'
                                    : 'Business')}
                        {type === authType.resetPswd && 'Reset Password'}
                    </ButtonActive>
                </div>
            </div>
        </OnboardingLayout>
    );
};
export default Auth;
const sideContent = [
    'Homegrown provides you with access to vetted, multi-location neighborhood businesses that want capital to open up more locations.',
    "They go to Homegrown because we're faster, friendlier, and more partnership-oriented than other financing options.",
];

const ErrorMsg: FC<PropsWithChildren> = ({ children }) => (
    <span className=" text-danger font-medium text-sm">{children}</span>
);

const PasswordPolicy: FC<{ policyString?: string; rules: PolicyRule[] }> = ({
    policyString,
    rules,
}) => {
    if (!policyString || !Array.isArray(rules)) return null;

    const parseRules = rules.map((rule) => {
        const items = rule.items?.map((item) => (
            <li className={`list-disc ${item.verified ? 'text-success' : ''}`}>
                {format(item)}
            </li>
        ));

        return (
            <li className={`${rule.verified ? 'text-success' : ''}`}>
                - {format(rule)}
                {items && <ul className="pl-6">{items}</ul>}
            </li>
        );
    });
    return (
        <div className="shadow-shadow rounded-xl px-2 desktop:px-9.5 py-2 my-4">
            <div className="text-base font-semibold text-[#979797] my-1.5">
                Your password must contain
            </div>
            <div className="my-2.5 leading-[187.5%] text-base font-medium">
                <ul>{parseRules}</ul>
            </div>
        </div>
    );
};

function format<T extends { message: string; format?: (string | number)[] }>({
    message,
    format,
}: T) {
    if (!format) return message;
    let i = 0;
    return message.replace(/%d/g, () => String(format[i++]));
}
